import React, { useEffect, useState } from "react";
import { useMapsLibrary, Map, useMap } from '@vis.gl/react-google-maps';
import { Autocomplete } from "./Autocomplete";
import { MarkerWithInfowindow } from "./MarkerWithInfowindow";
import { Badge, Button, Checkbox, Dropdown, Input, Label, Option, Select, Textarea } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";

export const NewIssueForm = ({ notify }) => {
    const [center, setCenter] = useState({ lat: 8, lng: 80.6337 });
    const [zoom, setZoom] = useState(1);
    const [newMarker, setNewMarker] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [anonymous, setAnonymous] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [priority, setPriority] = useState('Medium');
    const [honeypot, setHoneypot] = useState('');
    const [geocodingService, setGeocodingService] = useState(null);
    const [location, setLocation] = useState({});

    const map = useMap("new_issue");
    const navigate = useNavigate();
    const geocodingLibrary = useMapsLibrary('geocoding');

    const onZoomToCoordinates = (latLng) => {
        setCenter(latLng);
        setZoom(15);
        setNewMarker(latLng);
    }

    const getCenterFromBounds = () => {
        if (map) {
            const bounds = map.getBounds();
            if (bounds) {
                return bounds.getCenter();
            }
        }
        return null;
    };

    const addMarker = () => {
        const center = getCenterFromBounds();
        setNewMarker({ lat: center.lat(), lng: center.lng() });
    }

    useEffect(() => {
        if (!geocodingLibrary || !map) return;
        setGeocodingService(new geocodingLibrary.Geocoder());
    }, [geocodingLibrary, map]);

    useEffect(() => {
        if (newMarker && newMarker.lat && newMarker.lng && geocodingService) {
            geocodingService.geocode({
                location: {
                    lat: newMarker.lat,
                    lng: newMarker.lng
                }
            }).then(res => {
                let street1 = '', street2 = '', city = '', state = '', county = '', country = '', postalCode = '';
                const components = {
                    street1: ["street_number"],
                    street2: ["route"],
                    city: ["locality"],
                    county: ["administrative_area_level_2"],
                    state: ["administrative_area_level_1"],
                    postalCode: ["postal_code"],
                    country: ["country"],
                };

                if (res && res.results && res.results.length > 0) {
                    const result = res.results[0];
                    for (let i = 0; i < result.address_components.length; i++) {
                        const addressComponent = result.address_components[i];
                        const types = addressComponent.types;
                        for (const type of types) {
                            if (components.street1.includes(type) && !street1) {
                                street1 = addressComponent.long_name;
                            }
                            if (components.street2.includes(type) && !street2) {
                                street2 = addressComponent.long_name;
                            }
                            if (components.city.includes(type) && !city) {
                                city = addressComponent.long_name;
                            }
                            if (components.county.includes(type) && !county) {
                                county = addressComponent.long_name;
                            }
                            if (components.state.includes(type) && !state) {
                                state = addressComponent.long_name;
                            }
                            if (components.postalCode.includes(type) && !postalCode) {
                                postalCode = addressComponent.long_name;
                            }
                            if (components.country.includes(type) && !country) {
                                country = addressComponent.long_name;
                            }
                        }
                    }
                }

                setLocation({
                    street1,
                    street2,
                    city,
                    county,
                    state,
                    postalCode,
                    country,
                });
            });
        } else {
            setLocation({});
        }
    }, [newMarker]);

    const onRemoveMarker = () => {
        setNewMarker(null);
    }

    const submit = (e) => {
        e.preventDefault();
        if (honeypot) {
            return;
        }
        if (!newMarker) {
            notify("Please add a marker", "error");
            return;
        }

        const payload = {
            reporter_name: name,
            reporter_email: email,
            reporter_phone: phone,
            anonymous,
            priority: priority.toLowerCase(),
            title,
            description,
            coordinates: {
                latitude: newMarker.lat,
                longitude: newMarker.lng,
            },
            street1: location.street1,
            street2: location.street2,
            city: location.city,
            county: location.county,
            state: location.state,
            postal_code: location.postalCode,
            country: location.country,
        };
        fetch('/api/issues', {
            method: "POST",
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify(payload),
        })
            .then(resp => resp.json())
            .then(res => {
                if (res && res.id) {
                    navigate(`/${res.id}`);
                }
            })
            .catch(err => notify(err, 'error'));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 25 }}>
            <div style={{ width: '70%', marginBottom: 25 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                    <Autocomplete setCenter={onZoomToCoordinates} />
                    <div>
                        {!newMarker &&
                            <Button style={{ width: 100 }} appearance='primary' size='small' onClick={addMarker}>
                                Place Marker
                            </Button>
                            ||
                            <Button style={{ backgroundColor: 'darkred', width: 150, color: 'whitesmoke' }} appearance='secondary' size='small' onClick={onRemoveMarker}>Remove Marker</Button>
                        }
                    </div>
                </div>
                <div style={{ height: '30vh', width: '70%' }}>
                    <Map
                        mapId='bf51a910020fa25a'
                        zoom={zoom}
                        id="new_issue"
                        onCenterChanged={(ev) => setCenter(ev.detail.center)}
                        onZoomChanged={(ev) => setZoom(ev.detail.zoom)}
                        center={{ lat: 0, lng: 0 }}
                        disableDefaultUI={true}
                        zoomControl={true}
                        scaleControl={true}
                        zoomControlOptions={{
                            style: 1,
                            position: 7
                        }}
                        mapTypeControl={true}
                    >
                        {newMarker &&
                            <MarkerWithInfowindow setLatLng={setNewMarker} lat={newMarker.lat} lng={newMarker.lng} />
                        }
                    </Map>
                </div>
                <form onSubmit={submit} style={{ marginTop: 25, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                        <Label style={{ width: '20%' }} size='small' required>Title</Label>
                        <Input placeholder="Title" value={title} onChange={(ev, data) => setTitle(data.value)} style={{ width: '80%' }} size='small' autoComplete='none' required id='title' />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                        <Label style={{ width: '20%' }} size='small' required>Description</Label>
                        <Textarea placeholder="Description" value={description} onChange={(ev, data) => setDescription(data.value)} rows={5} style={{ width: '80%' }} size='small' autoComplete='none' id='description' />
                    </div>
                    <div style={{ visibility: 'hidden', position: 'absolute' }}>
                        <label htmlFor="sri_lanka_random">Don't fill this out if you're human:</label>
                        <Input onChange={(ev, data) => setHoneypot(data.value)} type="text" id="sri_lanka" name="sri_lanka" />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <Label style={{ width: '20%' }} size='small'>Name</Label>
                        <Input placeholder="Name" value={name} onChange={(ev, data) => setName(data.value)} style={{ width: '50%' }} size='small' autoComplete='none' />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                        <Label style={{ width: '20%' }} size='small'>Email</Label>
                        <Input placeholder="Email" value={email} onChange={(ev, data) => setEmail(data.value)} style={{ width: '50%' }} size='small' autoComplete='none' />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                        <Label style={{ width: '20%' }} size='small'>Phone</Label>
                        <Input placeholder="Phone Number" value={phone} onChange={(ev, data) => setPhone(data.value)} style={{ width: '50%' }} size='small' autoComplete='none' />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                        <Label style={{ width: '20%' }} size='small'>Hide Email/Phone</Label>
                        <Checkbox value={anonymous} onChange={(ev, data) => setAnonymous(data.checked)} size='small' id='anonymous' />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                        <Label style={{ width: '20%' }} size='small' required>Severity</Label>
                        <Dropdown
                            id='priority'
                            size='small'
                            value={priority}
                            onOptionSelect={(ev, data) => setPriority(data.optionValue)}
                        >
                            <Option text="Low">
                                <Badge color='brand' size='tiny' />Low
                            </Option>
                            <Option text="Medium">
                                <Badge color='important' size='tiny' />Medium
                            </Option>
                            <Option text="High">
                                <Badge color='danger' size='tiny' />High
                            </Option>
                        </Dropdown>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 25, columnGap: 5 }}>
                        <Button size='medium' appearance='secondary' onClick={() => navigate('/')}>Cancel</Button>
                        <Button type="submit" size='medium' appearance='primary'>Submit</Button>
                    </div>
                </form>
            </div>
        </div>
    );
};