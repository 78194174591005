import { Body1Strong, makeStyles, Subtitle1, Subtitle2, Toast, ToastBody, Toaster, ToastTitle, useId, useToastController } from "@fluentui/react-components";
import { AnimalDogRegular, BuildingFilled } from '@fluentui/react-icons';
import { IssuesHome } from "./pages/IssuesHome";
import { Issue } from "./pages/Issue";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { APIProvider } from "@vis.gl/react-google-maps";
import { NewIssueForm } from "./pages/NewIssueForm";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'hidden',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '50px',
  }
});

function App(props) {
  const toasterId = useId("toaster");
  const styles = useStyles();

  const { dispatchToast } = useToastController(toasterId);
  const notify = (content, intent) => {
    dispatchToast(
      <Toast>
        <ToastTitle subtitle="Subtitle">{content}</ToastTitle>
      </Toast>,
      { intent, position: 'top' }
    );
  }

  return (
    <div>
      <Toaster toasterId={toasterId} />
      <div className={styles.root}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Subtitle1 style={{ cursor: 'pointer' }} onClick={() => window.location.replace('/')}>Infra <BuildingFilled /> Watcher</Subtitle1>
          <Body1Strong>Providing visibility to infrastructure issues in your community</Body1Strong>
        </div>
        <div style={{ backgroundColor: 'whitesmoke', flex: 1, overflowY: 'auto', height: '100%', paddingBottom: 100 }}>
          <APIProvider apiKey='AIzaSyD9DJcHeHRuXfOoemsorJYaWUs9qQG6byo'>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={
                    <IssuesHome notify={notify} />
                  }
                />
                <Route
                  path="/new-issue"
                  element={
                    <NewIssueForm notify={notify} />
                  }
                />
                <Route
                  path="/:id"
                  element={
                    <Issue notify={notify} />
                  }
                />
              </Routes>
            </BrowserRouter>
          </APIProvider>
        </div>
        <div className={styles.footer}>
          <div style={{ textAlign: 'center' }}>
            Made with ♥️
          </div>
          <div style={{ textAlign: 'center' }}>
            Send Questions or Comments to <a href="mailto:sandunr@gmail.com">sandunr@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;