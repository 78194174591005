import { Avatar, Caption1Stronger, makeStyles, Text } from "@fluentui/react-components";
import { ThumbLikeRegular } from '@fluentui/react-icons';

const useStyles = makeStyles({
    container: {
        gap: "5px",
        display: "flex",
        width: '90%',
        flexDirection: "row",
        alignItems: "stretch",
        marginBottom: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: '15px',
    },
    thumbLike: {
        ":hover": {
            cursor: 'pointer',
        }
    },
});

export default function SingleComment({ comment, likeComment }) {
    const styles = useStyles();

    return (
        <div className={styles.container}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Caption1Stronger style={{color: '#146eb4'}}>{comment.commenter || 'Anonymous'}</Caption1Stronger>
                <Text align='justify' style={{ width: '100%', fontSize: '1em' }}>
                    {comment.comment}
                </Text>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', columnGap: 2}}>
                <ThumbLikeRegular className={styles.thumbLike} onClick={() => likeComment(comment.id)} fontSize={16} />
                <small>{comment.comment_likes}</small>
            </div>
        </div>
    );
}