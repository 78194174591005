import React, { useState } from 'react';
import {
    AdvancedMarker,
    useAdvancedMarkerRef
} from '@vis.gl/react-google-maps';

export const MarkerWithInfowindow = ({ lat, lng, setLatLng }) => {
    const [markerRef, marker] = useAdvancedMarkerRef();

    return (
        <>
            <AdvancedMarker
                ref={markerRef}
                position={{ lat, lng }}
                draggable
                onDragEnd={(e) => setLatLng({ lat: e.latLng.lat(), lng: e.latLng.lng() })}
                title={'AdvancedMarker d opens an Infowindow when clicked.'}
            />
        </>
    );
};