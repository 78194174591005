import { Button, Input, Label, Textarea } from "@fluentui/react-components";
import { useState } from "react";

export default function NewComment({ onSubmit }) {
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');

    const onSubmitComment = () => {
        onSubmit({
            commenter: name,
            comment,
        }).then(res => {
            if (res) {
                setName('');
                setComment('');
            }
        });
    }

    return (
        <div style={{marginTop: 30, width: '90%'}}>
            <Input size='small' value={name} onChange={(ev, data) => setName(data.value)} placeholder='Name' style={{width: '100%', marginBottom: 5 }}/>
            <Textarea 
                placeholder="Write a comment"
                style={{width: '100%'}}
                value={comment}
                size='small'
                rows={4}
                onChange={(ev, data) => setComment(data.value)}
            />
            <Button onClick={onSubmitComment} style={{marginTop: 10}} appearance='primary' size='small'>Submit</Button>
        </div>
    );
}