import React, { useEffect, useState, useCallback, FormEvent } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { Button, Combobox, Input, makeStyles, Option, Popover, PopoverSurface, PopoverTrigger, SearchBox } from '@fluentui/react-components';


const useStyles = makeStyles({
    search_dropdown: {
        ":hover": {
            backgroundColor: 'grey',
            cursor: 'pointer',
        }
    }
});

// This is a custom built autocomplete component using the "Autocomplete Service" for predictions
// and the "Places Service" for place details
export const Autocomplete = ({ setCenter }) => {
    const styles = useStyles();

    const map = useMap("new_issue");
    const places = useMapsLibrary('places');

    const [sessionToken, setSessionToken] = useState();
    const [autocompleteService, setAutocompleteService] = useState(null);
    const [placesService, setPlacesService] = useState(null);
    const [predictionResults, setPredictionResults] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedPlace, setSelectedPlace] = useState(null);

    useEffect(() => {
        if (!places || !map) return;

        setAutocompleteService(new places.AutocompleteService());
        setPlacesService(new places.PlacesService(map));
        setSessionToken(new places.AutocompleteSessionToken());

        return () => setAutocompleteService(null);
    }, [map, places]);

    const fetchPredictions = useCallback(
        async (inputValue) => {
            if (!autocompleteService || !inputValue) {
                setPredictionResults([]);
                return;
            }

            const request = { input: inputValue, sessionToken };
            const response = await autocompleteService.getPlacePredictions(request);

            setPredictionResults(response.predictions);
        },
        [autocompleteService, sessionToken]
    );

    const onInputChange = useCallback(
        (value) => {
            setInputValue(value);
            fetchPredictions(value);
        },
        [fetchPredictions]
    );

    const handleSuggestionClick = useCallback(
        (placeId) => {
            if (!places) return;

            const detailRequestOptions = {
                placeId,
                fields: ['geometry', 'name', 'formatted_address'],
                sessionToken
            };

            const detailsRequestCallback = (placeDetails) => {
                setSelectedPlace(placeDetails);
                const lat = placeDetails.geometry.location.lat();
                const lng = placeDetails.geometry.location.lng();
                setCenter({ lng, lat });
                setPredictionResults([]);
                setInputValue(placeDetails?.formatted_address ?? '');
                setSessionToken(new places.AutocompleteSessionToken());
            };

            placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
        },
        [selectedPlace, places, placesService, sessionToken]
    );

    return (
        <div style={{width: '100%'}}>
            <SearchBox
                placeholder="Search for a place"
                onChange={(ev, data) => onInputChange(data.value)}
                value={inputValue || ''}
                style={{width: '75%'}}
                size='small'
            />
            {predictionResults.length > 0 && (
                <ul style={{listStyle: 'none', position: 'absolute', zIndex: '40000', backgroundColor: 'white', margin: 0, padding: 5}}>
                    {predictionResults.map(({ place_id, description }) => {
                        return (
                            <li
                                key={place_id}
                                className={styles.search_dropdown}
                                onClick={() => handleSuggestionClick(place_id)}>
                                {description}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};